<template>
  <div class="col main_roles_wrapper_container">
    <card :title="`${localization('Edit Roles & Roles Permissions')}`">
      <card>
        <div class="row" style="display: flex; align-items: center">
          <div class="col-xl-4">
            <el-select
              v-model="role"
              filterable
              :placeholder="`${localization('Select Role')}`"
              style="width: 100%; direction: ltr"
            >
              <el-option
                v-for="(role, id) in $store.getters['roles/roles']"
                :key="id"
                :label="role.name"
                :value="role.id"
              >
                <span style="color: #8492a6; font-size: 13px">
                  <i class="el-icon-user-solid"></i>
                  {{ role.name }}
                </span>
              </el-option>
            </el-select>
          </div>
          <div class="col-xl-2 mb-2 mt-2">
            <el-input
              :placeholder="`${localization('Role Name')}`"
              v-model="selected_role.name"
              class="w-100 mt-1 mt-xl-0"
            ></el-input>
          </div>
          <div class="col-xl-6 main_container_wrapping_buttons">
            <el-button
              type="primary"
              icon="el-icon-plus"
              @click="role_dialog = true"
              class="w-100 text-center mt-1 mt-xl-0"
            >
              {{ localization("Add Role") }}
            </el-button>
            <el-button
              type="success"
              @click="update"
              icon="el-icon-folder-checked"
              class="w-100 text-center  mt-1 mt-xl-0"
              :disabled="role == null"
            >
              {{ localization("Update Role") }}
            </el-button>
            <el-button
              type="danger"
              @click="deleteRole"
              :disabled="role == null"
              icon="el-icon-delete"
              class="w-100 text-center mt-1 mt-xl-0"
            >
              {{ localization("Delete Role") }}
            </el-button>
          </div>
        </div>
      </card>

      <div>
        <div class="row">
          <div
            class="col-lg-4 col-md-6"
            v-for="(group, key) in $store.getters['roles/permissionsGroups']"
            :key="key"
          >
            <el-card
              v-loading="$store.getters['roles/rolesLoad']"
              :header="key"
              class="mb-3"
            >
              <el-checkbox-group
                v-for="(permission, id) in group"
                :key="id"
                v-model="Permissions"
                style="text-align: initial"
              >
                <el-checkbox
                  :label="`${permission.id}`"
                  :key="permission.id"
                  style="width: 100%"
                  >{{ permission.name }}
                </el-checkbox>
              </el-checkbox-group>
            </el-card>
          </div>
        </div>
      </div>
    </card>

    <!-- //? start add role dialog  -->
    <el-dialog
      :title="`${localization('Add Role')}`"
      class="add_role_dialog_wrapper"
      width="60%"
      :visible.sync="role_dialog"
      top="4vh"
    >
      <el-form :rules="rules" :model="selected_role" ref="ruleForm">
        <!-- <el-form-item label="Name" label-width="100%">
          <el-input
            v-model="selected_role.name"
            autocomplete="off"
            style="width: 100%"
          ></el-input>
        </el-form-item> -->
        <el-form-item
          :label="`${localization('Name')}`"
          style="width: 100%"
          prop="name"
        >
          <el-input
            type="text"
            v-model="selected_role.name"
            :placeholder="`${localization('Write a name')}`"
            style="width: 100%"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer dialog_footer">
        <el-button
          class="text-center text-capitalize save_button delete_button"
          @click="role_dialog = false"
          >{{ localization("Cancel") }}</el-button
        >
        <el-button
          class="text-center text-capitalize save_button"
          type="primary"
          @click="add"
        >
          {{ localization("Save Role") }}</el-button
        >
      </span>
    </el-dialog>
    <!-- //? end add role dialog  -->
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "index",
  data: () => ({
    role_dialog: false,
    role: null,
    selected_role: {},
    Permissions: [],
    rules: {
      name: [
        {
          required: true,
          message: "Please this field is required",
          trigger: "blur"
        }
      ]
    }
  }),
  watch: {
    role(val) {
      this.$store.dispatch("roles/getRolePermissions", val).then(_ => {
        this.Permissions = this.$store.getters["roles/rolePermissions"];
        this.selected_role = this.$store.getters["roles/roles"].filter(
          role => role.id == val
        )[0];
      });
    }
  },
  mounted() {
    this.Refresh();
  },

  methods: {
    Refresh() {
      this.$store.dispatch("roles/getRoles");
    },
    update() {
      // this.$refs["ruleForm"].validate(valid => {
        // if (valid) {
          this.$store
            .dispatch("roles/updateRole", {
              id: this.role,
              data: {
                name: this.selected_role.name,
                permissions: this.Permissions
              }
            })
            .then(_ => {
              Swal.fire({
                title: "",
                text: "Role has been updated.",
                type: "success",
                icon: "success",
                confirmButtonClass: "btn btn-success",
                buttonsStyling: false
              });
              this.Refresh();
            });
        // }
      // });
    },
    add() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          this.$store.dispatch("roles/addRole", this.selected_role).then(_ => {
            Swal.fire({
              title: "",
              text: "Role has been updated.",
              type: "success",
              confirmButtonClass: "btn btn-success",
              buttonsStyling: false,
              icon: "success"
            });
            this.Refresh();
            this.role_dialog = false;
            this.selected_role = {};
          });
        }
      });
    },
    deleteRole() {
      Swal.fire({
        title: "",
        text: `Are you sure you want delete this role`,
        type: "warning",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: "delete it!",
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store.dispatch("roles/deleteRole", this.role).then(_ => {
            this.Refresh(null);
            Swal.fire({
              title: "",
              text: "Your role has been deleted.",
              type: "success",
              icon: "success",
              confirmButtonClass: "btn btn-success",
              buttonsStyling: false
            });
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.main_roles_wrapper_container {
  .dialog_footer {
    display: flex;
    justify-content: end;
    align-items: center;
    @media (min-width: 320px) and (max-width: 991px) {
      justify-content: center;
    }
  }
}

.main_container_wrapping_buttons {
  display: flex;
  justify-content: space-between;
  @media (min-width: 320px) and (max-width: 480px) {
    flex-wrap: wrap;
    gap: 8px;
    .el-button + .el-button {
      margin-left: 0;
    }
  }
}
</style>

<style lang="scss">
.main_roles_wrapper_container {
  .el-dialog {
    @media (min-width: 320px) and (max-width: 767px) {
      width: 90% !important;
    }
  }
}
</style>
